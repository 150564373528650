//import logo from './logo.svg';
//import './App.css';
//import Example from './Example';

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './input.css'
import Login from './chklogin';
import Register from './Register'; 
//import Blog from './Blog';// Import หน้าสำหรับ Register
import student from './student';
import chklogin from './chklogin.php';

//import Register from './Register';


function App() {
  return (

    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/chklogin" element={<student />} />
      </Routes>
    </Router>
  );
}

export default App;
